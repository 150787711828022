import { ReactElement } from 'react'

export interface SplashPagePersonInfo {
    imageComponent: ReactElement
    firstName: string
    jepJobTitle: string
    currentEmployer: string
    currentEmployerJobTitle: string
    currentEmployerUrl: string
    personalLinkedInUrl: string
    description: ReactElement
}

export interface SplashPagePersonProps {
    info: SplashPagePersonInfo
    setAsActivePerson: () => void
    isActivePerson: boolean
}

export enum SplashPagePersonName {
    andrew = 'Andrew',
    spencer = 'Spencer',
    max = 'Maxim',
    luke = 'Luke',
    hao = 'Hao',
    sean = 'Sean',
    sue = 'Sue',
}
