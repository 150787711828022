import React, {
    createContext,
    PropsWithChildren,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { SetterOrUpdater } from 'recoil'
import { shouldUseTracking } from '@/openreplay/shouldUseTracking'
import type Tracker from '@openreplay/tracker'
import { buildFrontendLogger } from '@/logging/FrontendLogger'
import { TrackerMetadata } from '@/openreplay/TrackerProvider.types'
import { getSessionID } from '@/api/notLoggedIn'
import { CourseConfigType } from 'common/src/courseConfig'

const buildNewTracker = async (): Promise<Tracker> => {
    const { default: API } = await import('@openreplay/tracker')
    const trackerConfig = {
        projectKey: process.env.NEXT_PUBLIC_OPENREPLAY_PROJECT_KEY,
        // ingestPoint: 'https://openreplay.justenoughprep.com/ingest', uncomment to use a custom ingest point
        __DISABLE_SECURE_MODE: process.env.NEXT_PUBLIC_JEP_MODE === 'dev',
    }

    return new API(trackerConfig)
}

const logger = buildFrontendLogger('openreplay')

/*
    TODO: Upload source maps on build???
 */
interface OpenReplayTracker {
    metadata: TrackerMetadata
    setMetadata: SetterOrUpdater<TrackerMetadata>
}

export const OpenreplayContext = createContext<OpenReplayTracker | null>(null)

export const TrackerProvider: React.FC<
    PropsWithChildren<{ courseConfigType: CourseConfigType }>
> = ({ courseConfigType, children }) => {
    const isTrackingStartedRef = useRef<boolean>(false)
    const trackerRef = useRef<Tracker | null>(null)
    const isEnabled = useMemo((): boolean => shouldUseTracking(), [])
    const [metadata, setMetadata] = useState<TrackerMetadata | null>(null)

    const startTracking = useCallback(async (): Promise<void> => {
        if (isTrackingStartedRef.current) return
        if (isEnabled) {
            trackerRef.current = await buildNewTracker()
            logger.info('Starting openreplay tracker')
            await trackerRef.current.start()
            const sessionIDResponse = await getSessionID()
            if (!sessionIDResponse.data.success) {
                logger.error('Failed to retrieve session ID')
            } else {
                const sessionID = sessionIDResponse.data.payload.sessionID
                logger.info(`Setting session ID: ${sessionID}`)
                trackerRef.current.setMetadata('sessionID', sessionID)
            }

            if (courseConfigType) {
                logger.info(`Setting courseConfigType: ${courseConfigType}`)
                trackerRef.current.setMetadata(
                    'courseConfigType',
                    courseConfigType
                )
            }
        } else {
            logger.info('Intentionally not using open replay tracker')
        }
        isTrackingStartedRef.current = true
    }, [courseConfigType, isEnabled])

    useEffect(() => {
        startTracking()
    }, [startTracking])

    useEffect(() => {
        if (!isEnabled || !trackerRef.current) return

        logger.info(`Setting tracker metadata: ${JSON.stringify(metadata)}`)

        // set user email as ID
        if (metadata.userEmail) trackerRef.current.setUserID(metadata.userEmail)
        if (metadata.displayName)
            trackerRef.current.setMetadata('displayName', metadata.displayName)
        if (metadata.userID)
            trackerRef.current.setMetadata('userID', metadata.userID)
        if (metadata.courseID)
            trackerRef.current.setMetadata('courseID', metadata.courseID)
        if (metadata.courseName)
            trackerRef.current.setMetadata('courseName', metadata.courseName)
        if (metadata.isDemo)
            trackerRef.current.setMetadata('isDemo', String(metadata.isDemo))
    }, [isEnabled, metadata])

    return (
        <OpenreplayContext.Provider value={{ metadata, setMetadata }}>
            {children}
        </OpenreplayContext.Provider>
    )
}
