import {
    SplashPagePersonInfo,
    SplashPagePersonName,
} from '@/components/static/SplashPage/SplashPageTeam/SplashPagePerson/SplashPagePerson.types'
import React from 'react'
import { SpencerImage } from '@/components/static/images/avatars/SpencerImage'
import { AndrewImage } from '@/components/static/images/avatars/AndrewImage'
import { LillyImage } from '@/components/static/images/avatars/LillyImage'
import { SeanImage } from '@/components/static/images/avatars/SeanImage'
import { HaoImage } from '@/components/static/images/avatars/HaoImage'
import { LukeImage } from '@/components/static/images/avatars/LukeImage'
import { MaxImage } from '@/components/static/images/avatars/MaxImage'
import { CourseConfigType } from 'common/src/courseConfig'
import { MissingPersonImage } from '@/components/static/images/avatars/MissingPersonImage'
import { jepAssertNever } from 'common/src/utils/jepAssertNever'

export type SplashPagePeopleConfig = {
    [key in SplashPagePersonName]?: SplashPagePersonInfo
}
export const getSplashPagePeopleConfigForCourseConfigType = (
    courseConfigType: CourseConfigType
): SplashPagePeopleConfig => {
    switch (courseConfigType) {
        case CourseConfigType.fsaHealth:
            return SplashPagePeopleConfigFsaHealth
        case CourseConfigType.pharmacyTechnician:
            return SplashPagePeopleConfigPharmacyTechnician
        default:
            jepAssertNever(
                courseConfigType,
                'Unhandled courseConfigType for getSplashPagePeopleConfigForCourseConfigType'
            )
    }
}

const SplashPagePeopleConfigPharmacyTechnician: SplashPagePeopleConfig = {
    [SplashPagePersonName.andrew]: {
        imageComponent: <AndrewImage />,
        firstName: SplashPagePersonName.andrew,
        jepJobTitle: 'Founder & CEO',
        currentEmployer: 'SmartNews',
        currentEmployerJobTitle: 'Engineering',
        currentEmployerUrl: 'https://www.smartnews.com/en/',
        personalLinkedInUrl: 'https://www.linkedin.com/in/andrewjohngray/',
        description: (
            <>
                Based out of the South Bay, Andrew has had a variety of roles
                over the course of his career. Originally an actuary and now an
                engineering manager for a news recommendation system at a
                startup, the one thing that has never changed is Andrew&apos;s
                passion for education and helping those around him achieve their
                full potential. His unique background allows us to design
                research-backed, technologically innovative solutions to the
                problems faced by so many students today.
            </>
        ),
    },
    [SplashPagePersonName.sue]: {
        imageComponent: <MissingPersonImage />,
        firstName: SplashPagePersonName.sue,
        jepJobTitle: 'Content',
        currentEmployer: 'TODO',
        currentEmployerJobTitle: 'TODO',
        currentEmployerUrl: 'TODO',
        personalLinkedInUrl: 'TODO',
        description: (
            <>
                Dr. Sue Jing Pharm.D M.S is a registered pharmacist with more
                than 15 years’ experience in various healthcare settings. She
                has been teaching pharmacy technician certification courses
                since 2015. She enjoys helping her students prepare for their
                pharmacy technician career.
            </>
        ),
    },
} as const

const SplashPagePeopleConfigFsaHealth: SplashPagePeopleConfig = {
    [SplashPagePersonName.andrew]: {
        imageComponent: <AndrewImage />,
        firstName: SplashPagePersonName.andrew,
        jepJobTitle: 'Founder & CEO',
        currentEmployer: 'SmartNews',
        currentEmployerJobTitle: 'Engineering',
        currentEmployerUrl: 'https://www.smartnews.com/en/',
        personalLinkedInUrl: 'https://www.linkedin.com/in/andrewjohngray/',
        description: (
            <>
                Based out of the South Bay, Andrew spent the first part of his
                career earning his FSA actuarial credentials whilst managing a
                predictive modeling team, but has since transitioned to a career
                in machine learning engineering and is currently building
                recommendation systems at a personalized news platform. This
                unique background allows us to design research-backed,
                technologically innovative solutions to the problems faced by so
                many students today.
            </>
        ),
    },
    [SplashPagePersonName.sean]: {
        imageComponent: <SeanImage />,
        firstName: SplashPagePersonName.sean,
        jepJobTitle: 'Content',
        currentEmployer: 'Centene Corporation',
        currentEmployerJobTitle: 'Actuary',
        currentEmployerUrl: 'https://www.centene.com/',
        personalLinkedInUrl:
            'https://www.linkedin.com/in/sean-mcmahon-a62701183/',
        description: (
            <>
                Based out of Madison, Sean is both a passionate actuary and
                educator. He currently works as an actuary at Centene
                Corporation, where he prices Individual Marketplace products as
                part of the predictive modeling team. A man of various
                interests, he also owns a frozen yogurt shop - so he can answer
                any of your delicious frozen treat-related questions on top of
                helping you pass actuarial exams.
            </>
        ),
    },
    [SplashPagePersonName.hao]: {
        imageComponent: <HaoImage />,
        firstName: SplashPagePersonName.hao,
        jepJobTitle: 'Content',
        currentEmployer: 'Gallagher Re',
        currentEmployerJobTitle: 'Actuary',
        currentEmployerUrl: 'https://www.ajg.com/gallagherre/',
        personalLinkedInUrl: 'https://www.linkedin.com/in/hao-qin-43415b36/',
        description: (
            <>
                Also out of Chicago, Hao rounds out our team with his deep
                actuarial domain knowledge. Having previously worked at
                consulting firms, insurers, and reinsurers, Hao brings a diverse
                background to our team and is able to provide invaluable
                insights as to how our content should best be delivered to
                students. Hao is also a bit of a restaurant connoisseur.
                Don&apos;t be afraid to ask him for recommendations - they never
                disappoint.
            </>
        ),
    },
    [SplashPagePersonName.luke]: {
        imageComponent: <LukeImage />,
        firstName: SplashPagePersonName.luke,
        jepJobTitle: 'Data Science & Analytics',
        currentEmployer: 'Capital Rx',
        currentEmployerJobTitle: 'Underwriting Manager',
        currentEmployerUrl: 'https://www.cap-rx.com/',
        personalLinkedInUrl: 'https://www.linkedin.com/in/lukedauparas/',
        description: (
            <>
                Hailing from Chicago, Luke is a wearer of many hats. While his
                primary responsibilities involve building out our analytics
                infrastructure, Luke was previously an actuary for a large
                health insurer. His experience with the actuarial exam process
                has proved invaluable to our team through his contributions to
                both our content delivery methodology and the content itself.
            </>
        ),
    },
    [SplashPagePersonName.max]: {
        imageComponent: <MaxImage />,
        firstName: SplashPagePersonName.max,
        jepJobTitle: 'Engineering',
        currentEmployer: 'BitGo',
        currentEmployerJobTitle: 'Engineering',
        currentEmployerUrl: 'https://www.bitgo.com/',
        personalLinkedInUrl:
            'https://www.linkedin.com/in/maxim-turcan-77586a4a/',
        description: (
            <>
                Living in San Mateo, Max is a tenured software engineer in the
                financial sector. He brings crucial backend and infrastructure
                engineering expertise to JEP. When he isn&apos;t juggling
                building software at scale for a hedge fund and building
                innovative software at JEP, he loves to play video games and
                take his gorgeous cat Sherlock for walks.
            </>
        ),
    },
    [SplashPagePersonName.spencer]: {
        imageComponent: <SpencerImage />,
        firstName: SplashPagePersonName.spencer,
        jepJobTitle: 'Engineering',
        currentEmployerJobTitle: 'Engineering',
        currentEmployer: 'Verite Group',
        currentEmployerUrl: 'https://www.veritegroup.com/',
        personalLinkedInUrl:
            'https://www.linkedin.com/in/spencer-gray-638024126/',
        description: (
            <>
                Currently in Washington DC, Spencer works as a cybersecurity
                software engineer for a government contractor. As the youngest
                member on the team, Spencer brings a fresh perspective and a
                hunger to build the best possible product. When he isn&apos;t
                coding, Spencer likes to experiment with all manners of
                creations in the kitchen. Who could say no to flamin&apos; hot
                cheeto wings?
            </>
        ),
    },
} as const
