import { FsaHealthExam } from 'common/src/courseConfig'

export const DP = FsaHealthExam.design_and_pricing
export const DEMO_TEXT = 'Try free for 7 days'
export const WAITLIST_TEXT = 'Join Waitlist'
export const GET_STARTED_TEXT = 'Get started with JEP today'

export const REM_IN_PX = 16

export const ALREADY_OWNED_STRING = 'Already Owned'
export const CONTACT_PHONE_NUMBER_STRING = '(323) 844-2187'
